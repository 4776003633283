import React from 'react';
import '../CSS/UnderMaintenance.css';
import logo from '../Images/LogoStroke.png';

const Maintenance = () => {
  return (
    <div className='bigDiv'>
        <h1 className='bigTitle1'>ESTAMOS QUASE PRONTOS...</h1>
        <h1 className='smallTitle1'>Fiquem atentos, novidades em breve!</h1>

        <div className='logoContainer'>
            <img src={logo} className='logo' alt=""/>
        </div>

        <h1 className='bigTitle1'>WE ARE ALMOST READY...</h1>
        <h1 className='smallTitle1'>Stay tuned, news coming soon!</h1>
    </div>
  );
};

export default Maintenance;