import React, { useState } from 'react';
import axios from 'axios';
import '../CSS/ContactosContacts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import reclama from '../Images/reclama.png';
import logoIG from '../Images/logoIGverde.png';
import logoFB from '../Images/fbLogo.png';

const ContactosContacts = () => {

    const [topic, setTopic] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleTopicChange = (event) => {
        setTopic(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmission = () => {
        setEmail('');
        setMessage('');
        setTopic('');

        // Hide the icon after 5 seconds
        setTimeout(() => {
            setSubmitted(false);
        }, 5000);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const Email = {
            Sender: email,
            Topic: topic,
            Message: message
        };
            try {
                const response = await axios.post('https://personal-d4yby5if.outsystemscloud.com/Booking_API/rest/Communication/SendSupportEmail', Email);
                setSubmitted(true);
                if(response.data.HasError === true) {
                    setSubmitted(false);
                } else {
                    setSubmitted(true);
                    handleSubmission();
                }
            } catch (error) {
                setSubmitted(false);
            }        
    };
    
    return (
        <div>
        <div className='bodyDivWeb'>
            <p className='title'>Como chegar até nós?</p>

            <div className='divSeparatorCon'>
                <div className='divLeft'>
                    <p className='titleSmall'>Contactos</p>
                    <div className='line'>
                        <FontAwesomeIcon icon={faPhone} className='iconContacts'/>
                        <p className='text'>934 403 133</p>
                    </div>

                    <div className='line'>
                        <FontAwesomeIcon icon={faEnvelope} className='iconContacts'/>
                        <p className='text'>be.escaperoom@gmail.com</p>                        
                    </div>

                    <div className='lineMulti'>
                        <FontAwesomeIcon icon={faLocationDot} className='iconContacts'/>
                        <p className='text'>Avenida Abade de Baçal <br/>
                        Shopping do Loreto, Lj 41 <br/>
                        5300-068 Bragança</p> 
                    </div>

                    <div>
                        <p className='titleSmall'>Segue-nos:</p>
                        
                        <a href="https://www.instagram.com/escaperoom_braganca?igsh=MXViemI0ejdwb3RyZA==" target="_blank">
                            <img src={logoIG} className="iconRSociais" alt="" />
                        </a>

                        <a href="https://www.facebook.com/share/xZV9ZFvuyHpheewt/" target="_blank">
                            <img src={logoFB} className="iconRSociais" alt="" />
                        </a>
                    </div>

                    <div>
                        <p className='titleSmall'>Também temos:</p>
                        <a href="https://livroreclamacoes.pt/Inicio/" target="_blank">
                            <img src={reclama} className="iconReclama" alt="" />
                        </a>
                    </div>
                </div>

                <div className='divRight'>
                    <form onSubmit={handleSubmit}>
                        <p className='titleSmall'>Suporte</p>

                        <div className='line'>
                            <input className='inputs' placeholder='Email de envio' type="text" id="sender" value={email} onChange={handleEmailChange} required/>
                        </div>

                        <div className='line'>
                            <input className='inputs' placeholder='Assunto' type="text" id="topic" value={topic} onChange={handleTopicChange} required/>
                        </div>

                        <div className='line'>
                            <textarea className='inputBarsMultiline' rows={7} cols={48} value={message} onChange={handleMessageChange} placeholder="Mensagem"/>
                        </div>

                        <div className='linecenter'>
                            <button className="sendButton" type="submit">ENVIAR</button>

                            {submitted ? (
                                <div>
                                    <FontAwesomeIcon className='iconSent' icon={faCircleCheck}/>
                                </div>
                            ) : (<div></div>)}                            
                        </div>
                    </form>                    
                </div>
            </div>
        </div>

        {/*Mobile code*/}
        <div className='bodyDiv-mobile'>
            <p className='title-mobile'>Como chegar até nós?</p>

            <div className='divTop'>
                <p className='titleSmall-mobile'>Contactos</p>
                <div className='line-mobile'>
                    <FontAwesomeIcon icon={faPhone} className='iconContacts'/>
                    <p className='text-mobile'>934 403 133</p>
                </div>

                <div className='line-mobile'>
                    <FontAwesomeIcon icon={faEnvelope} className='iconContacts'/>
                    <p className='text-mobile'>be.escaperoom@gmail.com</p>                        
                </div>

                <div className='lineMulti'>
                    <FontAwesomeIcon icon={faLocationDot} className='iconContacts'/>
                    <p className='text-mobile'>Avenida Abade de Baçal <br/>
                    Shopping do Loreto, Lj 41 <br/>
                    5300-068 Bragança</p> 
                </div>

                <div>
                    <p className='titleSmall'>Segue-nos:</p>
                    
                    <a href="https://www.instagram.com/escaperoom_braganca?igsh=MXViemI0ejdwb3RyZA==" target="_blank">
                        <img src={logoIG} className="iconRSociais" alt="" />
                    </a>

                    <a href="https://www.facebook.com/share/xZV9ZFvuyHpheewt/" target="_blank">
                        <img src={logoFB} className="iconRSociais" alt="" />
                    </a>
                </div>

                <div>
                    <p className='titleSmall'>Também temos:</p>
                    <a href="https://livroreclamacoes.pt/Inicio/" target="_blank">
                        <img src={reclama} className="iconReclama" alt="" />
                    </a>
                </div>
            </div>

            <div className='divBot'>
                <form onSubmit={handleSubmit}>
                    <p className='titleSmall-mobile'>Suporte</p>

                    <div className='line-mobile'>
                        <input className='inputs-mobile ' placeholder='Email de envio' type="text" id="sender" value={email} onChange={handleEmailChange} required/>
                    </div>

                    <div className='line-mobile'>
                        <input className='inputs-mobile ' placeholder='Assunto' type="text" id="topic" value={topic} onChange={handleTopicChange} required/>
                    </div>

                    <div className='line-mobile'>
                        <textarea className='inputBarsMultiline-mobile' rows={10} cols={48} value={message} onChange={handleMessageChange} placeholder="Mensagem"/>
                    </div>

                    <div className='linecenter-mobile'>
                        <button className="sendButton" type="submit">ENVIAR</button>

                        {submitted ? (
                            <div>
                                <FontAwesomeIcon className='iconSent' icon={faCircleCheck}/>
                            </div>
                        ) : (<div></div>)}                            
                    </div>
                </form>                    
            </div>
        </div>

        <div className="divFooter">
            <p className="textFooter">Escape Room Bragança © {currentYear}</p>
        </div>     
    </div>
    );
};

export default ContactosContacts;