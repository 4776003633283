import React from 'react';
import '../CSS/QuemsomosAboutus.css';
import backgroundImage from '../Images/fundo2.png';
import logoAssinatura from '../Images/LogoStroke.png';

const Quemsomos = () => {

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    
    return (
        <div>
            <img src={backgroundImage} className='backImage2' alt="" />

            <div className='divCenteredContent'>
                <div className='divBigTitle'>
                    <p className='BigTitle'>Escape Room - Bragança</p>
                </div>

                <p className='regularText'>Somos uma equipa de entusiastas de escape rooms, e pretendemos que te tornes num também. Junta-te ao grupo!</p>

                <div className='div3Texts'>
                    <div>
                        <p className='textos3'>1. Encontra todas as pistas</p>
                    </div>

                    <div>
                        <p className='textos3'>2. Descobre como avançar nos enigmas</p>
                    </div>

                    <div>
                        <p className='textos3'>3. Tenta sair da sala o mais rápido possível</p>
                    </div>
                </div>

                <p className='regularText'>Queremos que todos consigam testar os próprios limites, independentemente da idade ou da língua que falam. 
                Contacta-nos para mais informações.</p>

                <p className='regularText'>Devido ao nível de dificuldade dos nossos desafios, recomendamos grupos de 2 a 4 pessoas. 
                No entanto, se te sentes confiante e queres pôr-te à prova, também o podes fazer sozinho!</p>

                <p className='regularTextYellow'>Traz os teus amigos e vem tentar escapar!</p>

                <div className='divAssinatura'>
                    <p>A equipa,</p>

                    <img src={logoAssinatura} className='logoImage' alt="" />
                </div>                
            </div>

            <div className="divFooter">
                <p className="textFooter">Escape Room Bragança © {currentYear}</p>
            </div> 
        </div>
    );
};

export default Quemsomos;