import React, { useState } from 'react';
import axios from 'axios';
import '../CSS/ContactosContacts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import reclama from '../Images/reclama.png';
import logoIG from '../Images/logoIGverde.png';
import logoFB from '../Images/fbLogo.png';

const ContactosContacts = () => {

    const [topic, setTopic] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleTopicChange = (event) => {
        setTopic(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmission = () => {
        setEmail('');
        setMessage('');
        setTopic('');

        // Hide the icon after 5 seconds
        setTimeout(() => {
            setSubmitted(false);
        }, 5000);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const Email = {
            Sender: email,
            Topic: topic,
            Message: message
        };
            try {
                const response = await axios.post('https://personal-d4yby5if.outsystemscloud.com/Booking_API/rest/Communication/SendSupportEmail', Email);
                setSubmitted(true);
                if(response.data.HasError === true) {
                    setSubmitted(false);
                } else {
                    setSubmitted(true);
                    handleSubmission();
                }
            } catch (error) {
                setSubmitted(false);
            }        
    };
    
    return (
        <div>
            <div className='bodyDivWeb'>
                <p className='title'>How to get to us?</p>

                <div className='divSeparatorCon'>
                    <div className='divLeft'>
                        <p className='titleSmall'>Contacts</p>
                        <div className='line'>
                            <FontAwesomeIcon icon={faPhone} className='iconContacts'/>
                            <p className='text'>934 403 133</p>
                        </div>

                        <div className='line'>
                            <FontAwesomeIcon icon={faEnvelope} className='iconContacts'/>
                            <p className='text'>be.escaperoom@gmail.com</p>                        
                        </div>

                        <div className='lineMulti'>
                            <FontAwesomeIcon icon={faLocationDot} className='iconContacts'/>
                            <p className='text'>Avenida Abade de Baçal <br/>
                            Shopping do Loreto, Lj 41 <br/>
                            5300-068 Bragança</p> 
                        </div>

                        <div>
                            <p className='titleSmall'>Follow us:</p>

                            <a href="https://www.instagram.com/escaperoom_braganca?igsh=MXViemI0ejdwb3RyZA==" target="_blank">
                                <img src={logoIG} className="iconRSociais" alt="" />
                            </a>

                            <a href="https://www.facebook.com/share/xZV9ZFvuyHpheewt/" target="_blank">
                                <img src={logoFB} className="iconRSociais" alt="" />
                            </a>
                        </div>

                        <div>
                            <p className='titleSmall'>We also have:</p>
                            <a href="https://livroreclamacoes.pt/Inicio/" target="_blank">
                                <img src={reclama} className="iconReclama" alt="" />
                            </a>
                        </div>
                    </div>

                    <div className='divRight'>
                        <form onSubmit={handleSubmit}>
                            <p className='titleSmall'>Support</p>

                            <div className='line'>
                                <input className='inputs' placeholder='Sending email' type="text" id="sender" value={email} onChange={handleEmailChange} required/>
                            </div>

                            <div className='line'>
                                <input className='inputs' placeholder='Topic' type="text" id="topic" value={topic} onChange={handleTopicChange} required/>
                            </div>

                            <div className='line'>
                                <textarea className='inputBarsMultiline' rows={7} cols={48} value={message} onChange={handleMessageChange} placeholder="Message"/>
                            </div>

                            <div className='linecenter'>
                                <button className="sendButton" type="submit">SEND</button>

                                {submitted ? (
                                    <div>
                                        <FontAwesomeIcon className='iconSent' icon={faCircleCheck}/>
                                    </div>
                                ) : (<div></div>)}                            
                            </div>
                        </form>                    
                    </div>
                </div>
            </div>

            {/*Mobile code*/}
            <div className='bodyDiv-mobile'>
                <p className='title-mobile'>How to get to us?</p>

                <div className='divTop'>
                    <p className='titleSmall-mobile'>Contacts</p>
                    <div className='line-mobile'>
                        <FontAwesomeIcon icon={faPhone} className='iconContacts'/>
                        <p className='text-mobile'>934 403 133</p>
                    </div>

                    <div className='line-mobile'>
                        <FontAwesomeIcon icon={faEnvelope} className='iconContacts'/>
                        <p className='text-mobile'>be.escaperoom@gmail.com</p>                        
                    </div>

                    <div className='lineMulti'>
                        <FontAwesomeIcon icon={faLocationDot} className='iconContacts'/>
                        <p className='text-mobile'>Avenida Abade de Baçal <br/>
                        Shopping do Loreto, Lj 41 <br/>
                        5300-068 Bragança</p> 
                    </div>

                    <div>
                        <p className='titleSmall'>Follow us:</p>
                        
                        <a href="https://www.instagram.com/escaperoom_braganca?igsh=MXViemI0ejdwb3RyZA==" target="_blank">
                            <img src={logoIG} className="iconRSociais" alt="" />
                        </a>

                        <a href="https://www.facebook.com/share/xZV9ZFvuyHpheewt/" target="_blank">
                            <img src={logoFB} className="iconRSociais" alt="" />
                        </a>
                    </div>

                    <div>
                        <p className='titleSmall'>We also have:</p>
                        <a href="https://livroreclamacoes.pt/Inicio/" target="_blank">
                            <img src={reclama} className="iconReclama" alt="" />
                        </a>
                    </div>
                </div>

                <div className='divBot'>
                    <form onSubmit={handleSubmit}>
                        <p className='titleSmall-mobile'>Support</p>

                        <div className='line-mobile'>
                            <input className='inputs-mobile ' placeholder='Sending email' type="text" id="sender" value={email} onChange={handleEmailChange} required/>
                        </div>

                        <div className='line-mobile'>
                            <input className='inputs-mobile ' placeholder='Topic' type="text" id="topic" value={topic} onChange={handleTopicChange} required/>
                        </div>

                        <div className='line-mobile'>
                            <textarea className='inputBarsMultiline-mobile' rows={10} cols={48} value={message} onChange={handleMessageChange} placeholder="Message"/>
                        </div>

                        <div className='linecenter-mobile'>
                            <button className="sendButton" type="submit">SEND</button>

                            {submitted ? (
                                <div>
                                    <FontAwesomeIcon className='iconSent' icon={faCircleCheck}/>
                                </div>
                            ) : (<div></div>)}                            
                        </div>
                    </form>                    
                </div>
            </div>

            <div className="divFooter">
                <p className="textFooter">Escape Room Bragança © {currentYear}</p>
            </div>
        </div>
    );
};

export default ContactosContacts;