import React, { useState, useEffect } from "react";
import './Header.css';
import { Link } from 'react-router-dom';
import logoBarra from '../../Images/logoBarra.png';
import logoPT from '../../Images/logoPT.png';
import logoEN from '../../Images/logoEN.png';

const HeaderApp = ({onHeaderClick}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); //mobile menu default = closed
  const [language, setLanguage] = useState('pt'); //language default = PT
  const [menuItemOpen, setMenuItem] = useState(2); //page open default = DESAFIOS

  useEffect(() => {    
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
        setLanguage(selectedLanguage);
    }
  }, []);

  //pass the language and the page to the main pages
  const handleClick = (item, lang) => {
    setLanguage(lang); //keep values for header management
    setMenuItem(item); //keep values for header management

    localStorage.setItem('selectedLanguage', lang); //keep language settings

    if(item === 3) {
      localStorage.setItem('selectedChallenge', '0'); //reset bookings settings
    }

    onHeaderClick(item, lang); //send the values to App.js
  }

  //pass the language and the page to the main pages, but closes mobile menu
  const handleClickMobile = (item, lang) => {
    setLanguage(lang); //keep values for header management
    setMenuItem(item); //keep values for header management

    localStorage.setItem('selectedLanguage', lang); //keep language settings

    if(item === 3) {
      localStorage.setItem('selectedChallenge', '0'); //reset bookings settings
    }

    toggleMenu();
    onHeaderClick(item, lang); //send the values to App.js
  }

  //open/close mobile menu
  const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      <div className="header-back">
        <div className="iconMenu-div">
          <button className="menu-button" onClick={toggleMenu}>&#9776;</button>
        </div>

        <div className="logoMenu-div">
          <a href="/">
            <img src={logoBarra} className='logo' alt="" onClick={() => handleClick(2, language)}/>
          </a>
        </div>

        <div className="icon-div">
          {language === 'pt' ? (<img src={logoPT} className="icon" alt=""/>) : (<img src={logoEN} className="icon" alt=""/>)}

          <p className={`iconLabel ${language === 'pt' ? 'iconLabel-selected' : ''}`} onClick={() => handleClick(menuItemOpen, 'pt')}>PT/</p>
          <p className={`iconLabel ${language === 'en' ? 'iconLabel-selected' : ''}`} onClick={() => handleClick(menuItemOpen, 'en')}>EN</p>
        </div>

        <div className="icon-div-mobile">
          {language === 'pt' ? (<img src={logoPT} className="icon" onClick={() => handleClick(menuItemOpen, 'en')} alt=""/>
                  ) : (<img src={logoEN} className="icon" onClick={() => handleClick(menuItemOpen, 'pt')} alt=""/>)}
        </div>

        <div className="menuDiv">
          <div>
            {language === 'pt' ? (
              <Link to={{ pathname: '/quemsomos' }} className={`menuOptions ${menuItemOpen === 1 ? 'menuOptions-selected' : ''}`} onClick={() => handleClick(1, language)}>
                QUEM SOMOS
              </Link>
            ) : (
              <Link to={{ pathname: '/aboutus' }} className={`menuOptions ${menuItemOpen === 1 ? 'menuOptions-selected' : ''}`} onClick={() => handleClick(1, language)}>
                ABOUT US
              </Link>
            )}
          </div>

          <div>
            {language === 'pt' ? (
              <Link to={{ pathname: '/desafios' }} className={`menuOptions ${menuItemOpen === 2 ? 'menuOptions-selected' : ''}`} onClick={() => handleClick(2, language)}>
              DESAFIOS
            </Link>
            ) : (
              <Link to={{ pathname: '/challenges' }} className={`menuOptions ${menuItemOpen === 2 ? 'menuOptions-selected' : ''}`} onClick={() => handleClick(2, language)}>
              CHALLENGES
              </Link>
            )}
          </div>

          <div>
            {language === 'pt' ? (
              <Link to={{ pathname: '/reservas' }} className={`menuOptions ${menuItemOpen === 3 ? 'menuOptions-selected' : ''}`} onClick={() => handleClick(3, language)}>
              RESERVAS
            </Link>
            ) : (
              <Link to={{ pathname: '/bookings' }} className={`menuOptions ${menuItemOpen === 3 ? 'menuOptions-selected' : ''}`} onClick={() => handleClick(3, language)}>
              BOOKINGS
            </Link>
            )}
          </div>

          <div>
            {language === 'pt' ? (
              <Link to={{ pathname: '/vouchers' }} className={`menuOptions ${menuItemOpen === 5 ? 'menuOptions-selected' : ''}`} onClick={() => handleClick(5, language)}>
              VOUCHERS
            </Link>
            ) : (
              <Link to={{ pathname: '/voucher' }} className={`menuOptions ${menuItemOpen === 5 ? 'menuOptions-selected' : ''}`} onClick={() => handleClick(5, language)}>
              VOUCHERS
            </Link>
            )}
          </div>
            
          <div>
            {language === 'pt' ? (
              <Link to={{ pathname: '/contactos' }} className={`menuOptions ${menuItemOpen === 4 ? 'menuOptions-selected' : ''}`} onClick={() => handleClick(4, language)}>
              CONTACTOS
            </Link>
            ) : (
              <Link to={{ pathname: '/contacts' }} className={`menuOptions ${menuItemOpen === 4 ? 'menuOptions-selected' : ''}`} onClick={() => handleClick(4, language)}>
              CONTACTS
            </Link>
            )}
          </div>
        </div>

        {isMenuOpen && (
          <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
            <div>
              {language === 'pt' ? (
                <Link to={{ pathname: '/quemsomos' }} className={`mobile-menuOptions ${menuItemOpen === 1 ? 'mobile-menuOptions-selected' : ''}`} onClick={() => handleClickMobile(1, language)}>
                QUEM SOMOS
              </Link>
            ) : (
              <Link to={{ pathname: '/aboutus' }} className={`mobile-menuOptions ${menuItemOpen === 1 ? 'mobile-menuOptions-selected' : ''}`} onClick={() => handleClickMobile(1, language)}>
                ABOUT US
              </Link>
              )}
            </div>

            <br/>

            <div>
              {language === 'pt' ? (
                <Link to={{ pathname: '/desafios' }} className={`mobile-menuOptions ${menuItemOpen === 2 ? 'mobile-menuOptions-selected' : ''}`} onClick={() => handleClickMobile(2, language)}>
                DESAFIOS
              </Link>
              ) : (
                <Link to={{ pathname: '/challenges' }} className={`mobile-menuOptions ${menuItemOpen === 2 ? 'mobile-menuOptions-selected' : ''}`} onClick={() => handleClickMobile(2, language)}>
                CHALLENGES
                </Link>
              )}
            </div>

            <br/>

            <div>
              {language === 'pt' ? (
                <Link to={{ pathname: '/reservas' }} className={`mobile-menuOptions ${menuItemOpen === 3 ? 'mobile-menuOptions-selected' : ''}`} onClick={() => handleClickMobile(3, language)}>
                RESERVAS
              </Link>
              ) : (
                <Link to={{ pathname: '/bookings' }} className={`mobile-menuOptions ${menuItemOpen === 3 ? 'mobile-menuOptions-selected' : ''}`} onClick={() => handleClickMobile(3, language)}>
                BOOKINGS
              </Link>
              )}
            </div>

            <br/>

            <div>
              {language === 'pt' ? (
                <Link to={{ pathname: '/vouchers' }} className={`mobile-menuOptions ${menuItemOpen === 5 ? 'mobile-menuOptions-selected' : ''}`} onClick={() => handleClickMobile(5, language)}>
                VOUCHERS
              </Link>
              ) : (
                <Link to={{ pathname: '/voucher' }} className={`mobile-menuOptions ${menuItemOpen === 5 ? 'mobile-menuOptions-selected' : ''}`} onClick={() => handleClickMobile(5, language)}>
                VOUCHERS
              </Link>
              )}
            </div>

            <br/>

            <div>
              {language === 'pt' ? (
                <Link to={{ pathname: '/contactos' }} className={`mobile-menuOptions ${menuItemOpen === 4 ? 'mobile-menuOptions-selected' : ''}`} onClick={() => handleClickMobile(4, language)}>
                CONTACTOS
              </Link>
              ) : (
                <Link to={{ pathname: '/contacts' }} className={`mobile-menuOptions ${menuItemOpen === 4 ? 'mobile-menuOptions-selected' : ''}`} onClick={() => handleClickMobile(4, language)}>
                CONTACTS
              </Link>
              )}
            </div>
          </div>            
        )}

      </div>   
    </header>
  );
};

export default HeaderApp;