import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const CookiesBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    // Check if the user has accepted cookies
    const cookiesAccepted = Cookies.get('cookies_accepted');
    if (cookiesAccepted === 'true') {
      // If cookies have been accepted, hide the banner
      setShowBanner(false);
    }
  }, []);

  const handleAcceptCookies = () => {

    Cookies.set('cookies_accepted', 'true', { expires: 365 }); // Expires after 365 days

    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <div id="cookies-banner" style={styles.banner}>
          <p style={styles.text}>This website uses cookies to ensure you get the best experience.</p>
          <button style={styles.button} onClick={handleAcceptCookies}>Got it!</button>
        </div>
      )}
    </>
  );
};

const styles = {
  banner: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#333',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
  },
  text: {
    margin: '0',
    marginBottom: '10px',
  },
  button: {
    backgroundColor: '#F4B366',
    color: '#1A132E',
    border: 'none',
    borderRadius: '3px',
    padding: '8px 16px',
    cursor: 'pointer',
    outline: 'none',
  },
};

export default CookiesBanner;