import React, { useState } from 'react';
import '../CSS/Vouchers.css';
import axios from 'axios';
import voucher from '../Images/GiftVoucher500500.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const VouchersEN = () => {

    const [step, setStep] = useState(1);
    const [confirmation, setConfirmationMessage] = useState(false);

    const [name, setName] = useState('');
    const [emailCompra, setEmailCompra] = useState('');
    const [emailPrenda, setEmailPrenda] = useState('');

    const Step = () => {
        return (
            <div className='stepsDiv'>
                <div className='step'>
                    <div className='stepCircle'>
                        <p className={`circle ${step === 1 || step === 2 || step === 3  ? 'circle-active' : 'circle'}`}>1</p>
                    </div>

                    <div className='stepText'>
                        <p>Voucher</p> 
                    </div>
                </div>

                <div className='step'>
                    <div className='stepCircle'>
                        <p className={`circle ${step === 2 || step === 3 ? 'circle-active' : 'circle'}`}>2</p>
                    </div>

                    <div className='stepText'>
                        <p>Information</p> 
                    </div>
                </div>

                <div className='step'>
                    <div className='stepCircle'>
                        <p className={`circle ${step === 3 ? 'circle-active' : 'circle'}`}>3</p>
                    </div>

                    <div className='stepText'>
                        <p>Check-out</p>
                    </div>
                </div>
            </div>
        );
    };

    const goBackButton = () => {
        setStep(step-1);
    };

    const goForwardButton = () => {
        setStep(step+1);
    };

    const go3Button = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(emailCompra) === true && name !== '')
            setStep(step+1);
    };

    const proceedPayment = () => {
        handleSubmit();
        setConfirmationMessage(true);
    };

    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleEmails = (event) => {
        setEmailCompra(event.target.value);
    };

    const handleEmailPrenda = (event) => {
        setEmailPrenda(event.target.value);
    };

    const handleSubmit = async () => {

        const Voucher = {
            Name: name,
            Email: emailCompra,
            GiftEmail: emailPrenda,
            Code: ""
        };
        try {
            await axios.post('https://personal-d4yby5if.outsystemscloud.com/Booking_API/rest/Vouchers/Create', Voucher);
        } catch (error) {
            
        }
        
    };
      
    return (
        <div>
            <Step/>

            {confirmation ? (
                <div className='mainDiv'>
                    <p className='textSimple'>Thank you! Keep an eye on your email to know what to do next.</p>
                </div>
            ) : (
                <div className='mainDiv'>                    
                    {step === 1 ? (
                        <div>
                            <div className='divCentrado'>
                                <div className='divVoucherLeft'>
                                    <img src={voucher} alt="Background" className='imageCard'/>
                                </div>

                                <div className='divVoucherRight'>
                                <p>Not sure what to gift that special someone? Looking for a unique present?</p>

                                <p>We have the perfect solution for you! Gift an experience at Escape Room Bragança.</p>

                                <p>Get our <span style={{ color: '#F4B366' }}>voucher</span> now and surprise them!</p>

                                <p>Each voucher costs 40€ and is valid for an experience for up to 4 people.</p>

                                </div>
                            </div>                            

                            <div className='buttonDiv'>
                                <button className='goButtonWeb' onClick={goForwardButton}>CONTINUE <FontAwesomeIcon icon={faArrowRight} className="hiddeniconBtn"/></button>
                            </div>
                        </div>                    
                    ) : (
                        step === 2 ? (
                            <div>

                                <div>
                                    <p className='textSimple'>We only need a name and an email for you to get the voucher!</p>
                                    <p className='textSimple'>If it’s a gift, you can provide the recipient’s details, and the voucher will be sent directly to them.</p>

                                    <br/>

                                    <label htmlFor="name" className='textSimpleYellow'>Name:</label>
                                    <label className="errorStar">*</label>
                                            <input
                                                className='inputLine'
                                                type="text"
                                                id="name"
                                                value={name}
                                                onChange={handleName}
                                                required
                                            />

                                    <br/>
                                    <br/>

                                    <label htmlFor="emailCompra" className="textSimpleYellow">Email:</label>
                                    <label className="errorStar">*</label>
                                    <input
                                        className="inputLine"
                                        type="text"
                                        id="emailCompra"
                                        value={emailCompra}
                                        onChange={handleEmails}
                                        required
                                    />

                                    <br/>
                                    <br/>

                                    <label htmlFor="emailPrenda" className="textSimpleYellow">Gift email (optional):</label>
                                    <input
                                        className="inputLine"
                                        type="text"
                                        id="emailPrenda"
                                        value={emailPrenda}
                                        onChange={handleEmailPrenda}
                                        required
                                    />
                                </div>

                                <br/>

                                <div className='buttonDiv'>
                                    <button className='gobackButton' onClick={goBackButton}><FontAwesomeIcon icon={faAngleLeft}/> BACK</button>
                                    <button className='goButtonWeb' onClick={go3Button}>CHECK-OUT <FontAwesomeIcon icon={faArrowRight} className="hiddeniconBtn"/></button>
                                </div>
                            </div>  
                        ) : (
                            <div>
                                <p className='textSimple'>The online payment option will be available soon.</p>
                                <p className='textSimple'>Until then, your voucher request will be processed via email (check the email you provided in the previous step so we can get in touch with you).</p>
                                <p className='textSimple'>By clicking FINISH, a voucher request will be submitted. Keep an eye on your email for further instructions.</p>

                            <div className='buttonDiv'>
                                <button className='gobackButton' onClick={goBackButton}><FontAwesomeIcon icon={faAngleLeft}/> BACK</button>
                                <button className='goButtonWeb' onClick={proceedPayment}>FINISH <FontAwesomeIcon icon={faCheck} className="hiddeniconBtn"/></button>
                            </div>
                        </div>  
                        )
                    )}
                </div>
            )}          

        </div>
    );

};

export default VouchersEN;