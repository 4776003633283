import React, { useState, useEffect } from 'react';
import '../CSS/DesafiosChallenges.css';
/*import SummerBanner from './../Components/Banner/SummerPromotion';*/
/*import backgroundImage from '../Images/Imagem3.png';*/
import backgroundImage from '../Images/fundoNatal.png';
import backgroundImageMobile from '../Images/fundoNatalCard.png';
import { Link } from 'react-router-dom';
/*import cardImage from '../Images/card.png';*/
import cardImage from '../Images/cardNatal.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece, faUsers, faClock } from '@fortawesome/free-solid-svg-icons';
import puzzleEmpty from '../Images/iconpuzzle.png';

const Desafios = () => {

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const [showDescription, setDescriptionVisible] = useState(false);
    const [showNotes, setNotesVisible] = useState(false);

    const [showNotesMobile, setNotesMobileVisible] = useState(false);
    const [showDescriptionMobile, setDescriptionMobileVisible] = useState(false);
    const [showChallengeMobile, setChallengeMobileVisible] = useState(true);
    
    //List of current challenges
    //Each element has a title, description, level, duration, and participants
    const [challenges, setChallenges] = useState([]);

    useEffect(() => {
        //fetch available challenges
        const fetchChallenges = async () => {
            try {
                const response = await fetch('https://personal-d4yby5if.outsystemscloud.com/Booking_API/rest/Challenges/v2/Get');
                if (!response.ok) {
                    throw new Error('Failed to fetch challenges');
                }
                const data = await response.json();
        
                setChallenges(data);
        
            } catch (error) {
                console.error('Error fetching challenges:');
            }
        };

        fetchChallenges();
    
    }, []);

    const handleClick = (desafioId) => {
        localStorage.setItem('selectedChallenge', desafioId);
    };

    const changeViewRight = () => {
        setNotesVisible(!showNotes);
    }

    const changeViewLeft = () => {
        setDescriptionVisible(!showDescription);
    }

    const changeSeparator1 = () => {
        setChallengeMobileVisible(true);
        setNotesMobileVisible(false);
        setDescriptionMobileVisible(false);
    }

    const changeSeparator2 = () => {
        setChallengeMobileVisible(false);
        setNotesMobileVisible(false);
        setDescriptionMobileVisible(true);
    }

    const changeSeparator3 = () => {
        setChallengeMobileVisible(false);
        setNotesMobileVisible(true);
        setDescriptionMobileVisible(false);
    }

    const LevelChallenge = (props) => {
        const {level} = props;

        return (
            level === 1 ? (
                <div className='divTitle'>
                    <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                    <img src={puzzleEmpty} className='iconStyle' alt="" />
                    <img src={puzzleEmpty} className='iconStyle' alt="" />
                    <img src={puzzleEmpty} className='iconStyle' alt="" />
                    <img src={puzzleEmpty} className='iconStyle' alt="" />
                </div>
            ) : (
                level === 2 ? (
                    <div className='divTitle'>
                        <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                        <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                        <img src={puzzleEmpty} className='iconStyle' alt="" />
                        <img src={puzzleEmpty} className='iconStyle' alt="" />
                        <img src={puzzleEmpty} className='iconStyle' alt="" />
                    </div>
                ) : (
                    level === 3 ? (
                        <div className='divTitle'>
                            <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                            <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                            <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                            <img src={puzzleEmpty} className='iconStyle' alt="" />
                            <img src={puzzleEmpty} className='iconStyle' alt="" />
                        </div>
                    ) : (
                        level === 4 ? (
                            <div className='divTitle'>
                                <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                                <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                                <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                                <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                                <img src={puzzleEmpty} className='iconStyle' alt="" />
                            </div>
                        ) : (
                            <div className='divTitle'>
                                <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                                <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                                <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                                <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                                <FontAwesomeIcon icon={faPuzzlePiece} className='iconStyle' />
                            </div>
                        )   
                    )   
                )                
            )            
        );
    }

    const CardChallenges = (props) => {
        const { challenge } = props;

        return (
            <div className='challengeCard'>
                    <div className='challengeCardLeft'>
                        {showDescription ? (
                            <div className='divClick' onClick={changeViewLeft}>
                                <p>{challenge.DescriptionEN}</p>
                            </div>) : (<img src={cardImage} className='cardImage' alt="" onClick={changeViewLeft}/>)}
                        
                    </div>

                    <div className='challengeCardRight'>
                        {showNotes ? (
                            <div>
                                <div className='divPrices'>
                                    <Link className='yellowCorner' onClick={changeViewRight}>challenge</Link>
                                </div>

                                <p className='marginLefttext'>Group of 4 elements: 45€</p>
                                <p className='marginLefttext'>Group of 3 elements: 40€</p>
                                <p className='marginLefttext'>Group of 2 elements: 35€</p>
                                <p className='marginLefttext'>Only 1 element: 25€</p>
                                {/*
                                <p className='marginLefttext'>Group of 4 elements: <a className='textoRiscado'>45€</a> 40€</p>
                                <p className='marginLefttext'>Group of 3 elements:  <a className='textoRiscado'>40€</a> 35€</p>
                                <p className='marginLefttext'>Group of 2 elements:  <a className='textoRiscado'>35€</a> 30€</p>
                                <p className='marginLefttext'>Only 1 element:  <a className='textoRiscado'>25€</a> 20€</p>
                                 */}
                                <p className='marginLefttext'>Other types: get in touch</p>
                            </div>
                        ) : (
                            <div>
                                <div className='divPrices'>
                                    <Link className='yellowCorner' onClick={changeViewRight}>prices</Link>
                                </div>

                                <div className='divTitle'>
                                    <p className='regTextYellow'>{challenge.TitleEN}</p>
                                </div>                       

                                <div className='div3icons'>
                                    <div className='divTitle'>
                                        <FontAwesomeIcon icon={faClock} className='iconStyle' />
                                        <p className='textSeparator'>{challenge.Duration}</p>
                                    </div>

                                    <div className='divTitle'>
                                        <FontAwesomeIcon icon={faUsers} className='iconStyle' />
                                        <p className='textSeparator'>{challenge.Participants}</p>
                                    </div>

                                    <LevelChallenge level={challenge.Level}/>
                                </div>

                                <div className='divTitle'>
                                    <Link className='yellowButtonSmall' to='/bookings' onClick={() => handleClick(challenge.ChallengeId)}>BOOK</Link>
                                </div>
                            </div>
                        )}                    
                    </div>                    
                </div>
        );
    }

    const CardChallengeMobile = (props) => {
        const { challenge } = props;

        return (
            <div className='mobileSeparator'>
                <div className='divTabs'>
                    <div className='divSeparatorAbas' onClick={changeSeparator1}>
                        <p>Challenge</p>
                    </div>

                    <div className='divSeparatorAbas' onClick={changeSeparator2}>
                        <p>Story</p>
                    </div>

                    <div className='divSeparatorAbas' onClick={changeSeparator3}>
                        <p>Prices</p>
                    </div>                        
                </div>

                <div className='challengeCard-mobile'>            
                    {showChallengeMobile ? (
                        <div>
                            <div className='challengeCardUnique'>
                                <div className='divTitle'>
                                    <p className='regTextYellow'>{challenge.TitleEN}</p>
                                </div>                        

                                <div className='div3icons-mobile'>
                                    <div className='divTitle-mobile'>
                                        <FontAwesomeIcon icon={faClock} className='iconStyle' />
                                        <p className='textSeparator'>{challenge.Duration}</p>
                                    </div>

                                    <div className='divTitle-mobile'>
                                        <FontAwesomeIcon icon={faUsers} className='iconStyle' />
                                        <p className='textSeparator'>{challenge.Participants}</p>
                                    </div>                            
                                </div>

                                <LevelChallenge level={challenge.Level}/>

                                <div className='divTitle-mobile'>
                                    <Link className='yellowButtonSmall' to='/bookings' onClick={() => handleClick(challenge.ChallengeId)}>BOOK</Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        
                        showNotesMobile ? (<div>
                                <p className='marginLefttext'>Group of 4 elements: 45€</p>
                                <p className='marginLefttext'>Group of 3 elements: 40€</p>
                                <p className='marginLefttext'>Group of 2 elements: 35€</p>
                                <p className='marginLefttext'>Only 1 element: 25€</p>
                                {/*
                                <p className='marginLefttext'>Group of 4 elements: <a className='textoRiscado'>45€</a> 40€</p>
                                <p className='marginLefttext'>Group of 3 elements:  <a className='textoRiscado'>40€</a> 35€</p>
                                <p className='marginLefttext'>Group of 2 elements:  <a className='textoRiscado'>35€</a> 30€</p>
                                <p className='marginLefttext'>Only 1 element:  <a className='textoRiscado'>25€</a> 20€</p>
                                 */}
                                <p className='marginLefttext'>Other types: get in touch</p>
                        </div>) : (
                            <div>
                                <p>{challenge.DescriptionEN}</p>
                            </div>)                    
                        )}         
                </div>
            </div>
        );
    }

    return (
        <div>
            {/*<SummerBanner></SummerBanner>*/}
            <div className='cardDiv'>
                <p className='title1'>
                    MISSION:<br />
                    SAVE CHRISTMAS
                </p>

                <p className='title2'>- Can you recover the magic? -</p>

                <div className='centered-button'>
                    <Link
                        className='yellowButton'
                        to='/bookings'
                        onClick={() => handleClick('2')}
                    >
                        BOOK HERE!
                    </Link>
                </div>
            </div>

            <picture className='backImage'>
                <source
                    media="(max-width: 600px)"
                    srcSet={backgroundImageMobile}
                />
                <img
                    src={backgroundImage}
                    alt="Background"
                    className="backImage"
                />
            </picture>

            <div className='centeredDiv'>
                <p className='regText'>Our challenges</p>

                <div>
                    {challenges.map((challenge) => (<CardChallenges key={challenge.ChallengeId} challenge={challenge}/>))}
                </div>
                
                <div>
                    {challenges.map((challenge) => (<CardChallengeMobile key={challenge.ChallengeId} challenge={challenge}/>))}
                </div>

                <div className="divFooter">
                    <p className="textFooter">Escape Room Bragança © {currentYear}</p>
                </div>
            </div>
        </div>
    );
};

export default Desafios;