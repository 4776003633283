import React from 'react';
import '../CSS/QuemsomosAboutus.css';
import backgroundImage from '../Images/fundo2.png';
import logoAssinatura from '../Images/LogoStroke.png';

const Aboutus = () => {

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    
    return (
        <div>
            <img src={backgroundImage} className='backImage2' alt="" />

            <div className='divCenteredContent'>
                <div className='divBigTitle'>
                    <p className='BigTitle'>Escape Room - Bragança</p>
                </div>

                <p className='regularText'>We are a team of escape room enthusiasts, and we want you to become one too. Join the group!</p>

                <div className='div3Texts'>
                    <div>
                        <p className='textos3'>1. Find all the clues</p>
                    </div>

                    <div>
                        <p className='textos3'>2. Discover how to progress through the puzzles</p>
                    </div>

                    <div>
                        <p className='textos3'>3. Try to escape the room as quickly as possible</p>
                    </div>
                </div>

                <p className='regularText'>We want everyone to be able to push their limits, regardless of age or language spoken. Contact us for more information.</p>

                <p className='regularText'>Due to the difficulty level of our challenges, we recommend groups of 2 to 4 people. However, if you feel confident and want to test yourself, you can also do it alone!</p>

                <p className='regularTextYellow'>Bring your friends and come try to escape!</p>

                <div className='divAssinatura'>
                    <p>The team,</p>

                    <img src={logoAssinatura} className='logoImage' alt="" />
                </div>                
            </div>

            <div className="divFooter">
                <p className="textFooter">Escape Room Bragança © {currentYear}</p>
            </div> 
        </div>
    );
};

export default Aboutus;